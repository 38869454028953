import "@fortawesome/fontawesome-pro/css/all.css";
import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "fa",
  },
  theme: {
    themes: {
      light: {
        main: "#BF7834",
        sub: "#6F4D3E",
        footer: "#EFA718",
      },
    },
    options: { customProperties: true },
  },
});
