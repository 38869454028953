import Vue from "vue";
import Vuex from "vuex";
import SecureLS from "secure-ls";
const ls = new SecureLS({ encodingType: "aes", isCompression: false });

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    drawer: false,
    loading: false,
  },
  mutations: {
    drawer(state, params) {
      state.drawer = params;
    },
    showLoading(state) {
      state.loading = true;
    },
    hideLoading(state) {
      state.loading = false;
    },
  },
  actions: {},
  modules: {},
  getters: {
    drawer: (state) => state.drawer,
    loading: (state) => state.loading,
  },
});
